import React, { useState } from "react";
import { Link } from "react-router-dom";
import { usePadStatus } from "./helper/useStats";

import logo from "../../images/logo.png";
import incubator from "../../images/products/incubator.png";
import earnest from "../../images/products/earnest.png";
import etcswap from "../../images/products/etcswap.png";
import gecko from "../../images/products/gecko.png";
import hummingbot from "../../images/products/hummingbot-color.png";
import usc from "../../images/products/usc.png";

export default function Home() {
  const stats = usePadStatus();

  return (
    <React.Fragment>
      <section id="home" className="project-area pt-0">
        <div className="container px-2 px-sm-5 py-5">
          <div className="text-center mt-5">
            <h3 className="text-uppercase">
              Incubator
            </h3>
            <h4 className="text-uppercase">
              Crowdfunding Platform
            </h4>
          </div>
          <div className="text-center mt-5">
            <Link to="/token">
              <button className="btn">Create A Token</button>
            </Link>
            <Link to="/sale-list">
              <button className="btn">Active Campaigns</button>
            </Link>
            <Link to="/token-locked">
              <button className="btn">Token Vaults</button>
            </Link>
          </div>

          <div className="row my-5">
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>${stats.totalLiquidityRaised}</h3>
                <p className="">Total Liquidity Raised</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>{stats.totalProjects}</h3>
                <p>Total Projects</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>{stats.totalParticipants}</h3>
                <p>Total Participants</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card text-center my-2 pt-0 pb-4">
                <h3>${stats.totalValueLocked}</h3>
                <p>Total Value Locked</p>
              </div>
            </div>
          </div>


          <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center">
            <h3 className="text-uppercase col-12 col-md-10 text-center">
              A Composable DeFi Protocol Ecosystem
            </h3>
            <p className="col-12 col-md-10 text-center">
              We built a suite of tools for the world of decentralized finance on Ethereum Classic.
            </p>
          </div>
          <div className="row my-5">
            <div className="col-12 col-md-6 col-xl-6">
              <a href="https://incubator.lol" target="_blank" rel="noopener noreferrer" className="card-link no-link">
                <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                  <img src={incubator} alt="Brand Logo" width="50px" />
                  <h4 className="my-2">Incubator</h4>
                  <p className="">Incubator is a decentralized launchpad for token presales, enabling secure fundraising, token creation, and liquidity provision. Liquidity is deposited into ETCswapV2.</p>
                </div>
              </a>
            </div>

            <div className="col-12 col-md-6 col-xl-6">
              <a href="https://usc.earnest.fun" target="_blank" rel="noopener noreferrer" className="card-link no-link">
                <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                  <img src={earnest} alt="Brand Logo" width="50px" />
                  <h4 className="my-2">Earnest.fun</h4>
                  <p className="">Earnest.fun is an easy token generation platform for initial DEX market formation, offering fair-launch DEX liquidity pools for secure token launches. Liquidity is deposited into ETCswapV2.</p>
                </div>
              </a>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-12 col-md-6 col-xl-6">
              <a href="https://v2.etcswap.org" target="_blank" rel="noopener noreferrer" className="card-link no-link">
                <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                  <img src={etcswap} alt="Brand Logo" width="50px" />
                  <h4 className="my-2">ETCswap V2</h4>
                  <p className="">ETCswap V2 is a decentralized exchange offering efficient ERC-20 token swaps, low fees, price oracles, flash swaps, and fungible liquidity tokens.</p>
                </div>
              </a>
            </div>

            <div className="col-12 col-md-6 col-xl-6">
              <a href="https://classicusd.com" target="_blank" rel="noopener noreferrer" className="card-link no-link">
                <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                  <img src={usc} alt="Brand Logo" width="50px" />
                  <h4 className="my-2">Classic USD</h4>
                  <p className="">Classic USD is a fiat-backed, regulated stablecoin on Ethereum Classic, offering capital efficiency and stability for secure digital transactions.</p>
                </div>
              </a>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-12 col-md-6 col-xl-6">
              <a href="https://www.geckoterminal.com/ethereum_classic/etcswap-v2/pools" target="_blank" rel="noopener noreferrer" className="card-link no-link">
                <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                  <img src={gecko} alt="Brand Logo" width="50px" />
                  <h4 className="my-2">Gecko Terminal</h4>
                  <p className="">Gecko Terminal offers real-time price tracking, advanced charting, liquidity pool monitoring, on-chain data analysis, and market insights for crypto traders and enthusiasts.</p>
                </div>
              </a>
            </div>

            <div className="col-12 col-md-6 col-xl-6">
              <a href="https://hummingbot.org/exchanges/etcSwap/" target="_blank" rel="noopener noreferrer" className="card-link no-link">
                <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                  <img src={hummingbot} alt="Brand Logo" width="50px" />
                  <h4 className="my-2">Hummingbot</h4>
                  <p className="">Hummingbot is an open-source crypto trading bot for automated market making and liquidity provision on major exchanges.</p>
                </div>
              </a>
            </div>
          </div>


          {/* Second Box */}
          <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center">
            <h3 className="text-uppercase col-12 col-md-10 text-center">
              A Suite of Tools for Crowdfunding Campaigns.
            </h3>
            <p className="col-12 col-md-10 text-center">
              A suite of tools were built to help you create your own tokens and
              fundraising campaigns in a fast, simple and cheap way, with no prior code
              knowledge required. Start a crowdfund campaign today!
            </p>
          </div>
          <div className="row my-5">



            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Standard Tokens</h4>
                <p className="">
                  Mint standard ERC20 tokens on Ethereum Classic.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Deflationary Tokens</h4>
                <p>
                  Generate deflationary tokens with tax and/or charity functions.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Private Campaigns</h4>
                <p>Create a unique crowdfunding campaign for your own custom token easily.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Public Crowdfund</h4>
                <p>
                  Use the token you mint to create a fundraising campaign with just a few clicks.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Listing Branding</h4>
                <p className="">
                  Adding logo, social links, description, listing on Incubator.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Listing Management</h4>
                <p>
                  The portal to help you easily update content for your campaign.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Build Communities</h4>
                <p>Promote your campaign for participation by the general public on Ethereum Classic.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card d-flex flex-column justify-content-center align-items-center text-center my-2 pt-4 pb-4">
                <img src={logo} alt="Brand Logo" width="50px" />
                <h4 className="my-2">Token Vaults</h4>
                <p>
                  Deposit your liquidity into ETCswapV2 and lock your liquidity into vaults after the fundraising campaign.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 text-white" align="center">
            Disclaimer:
            Incubator will never endorse or encourage that you invest in any of the projects listed and therefore, accept no liability for any loss occasioned. It is the user(s) responsibility to do their own research and seek financial advice from a professional.
            <br />
            <br />
            ©️ 2025 Incubator. All rights reserved.
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
