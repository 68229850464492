export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

export const coinArray = {
  61: "ETC",
  63: "METC"
}

export const convertDay = (mins) => {
  if (parseInt(mins) < 60)
    return mins + " mins";
  else if (parseInt(mins) < 1440)
    return Math.floor(mins / 60) + " hrs " + (mins % 60) + " mins";
  else if (parseInt(mins) < 1440) {
    let days = Math.floor(mins / 1440);
    let hours = Math.floor((mins - days * 1440) / 60);
    let minutes = mins % 60;
    return days + " days " + hours + " hours " + minutes + " mins";
  }
}

export const contract = {
  61: {
    poolfactory: "0x1D5abc08FF77AFB4B7AC4B4CaBd3893D53d35406",
    poolmanager: "0x53f11c7a843B2D264cAE1E4d542D600f4b05596b",
    routeraddress: "0x582A87594c86b204920f9e337537b5Aa1fefC07C",
    multicallAddress: "0xc4Fa95Fbf3A54d4A6870986B210096A92C751962",
    lockAddress: "0x0cb512b47b5B01687e01b5155C208e66768C823e",
    routername: "ETCswapV2",
    feeReceiver: "0xED011D569F3C07A7cd75020e35f7772A4B0392d7",
    dividendTracker: "0x1230992f9D209e271d6B3367677F5c177ADa1584",
  },
  63: {
    poolfactory: "0x1D5abc08FF77AFB4B7AC4B4CaBd3893D53d35406",
    poolmanager: "0x53f11c7a843B2D264cAE1E4d542D600f4b05596b",
    routeraddress: "0x582A87594c86b204920f9e337537b5Aa1fefC07C",
    multicallAddress: "0xc4Fa95Fbf3A54d4A6870986B210096A92C751962",
    lockAddress: "0x0cb512b47b5B01687e01b5155C208e66768C823e",
    routername: "ETCswapV2",
    feeReceiver: "0xED011D569F3C07A7cd75020e35f7772A4B0392d7",
    dividendTracker: "0x1230992f9D209e271d6B3367677F5c177ADa1584",
  },
  default: {
    poolfactory: "0x1D5abc08FF77AFB4B7AC4B4CaBd3893D53d35406",
    poolmanager: "0x53f11c7a843B2D264cAE1E4d542D600f4b05596b",
    routeraddress: "0x582A87594c86b204920f9e337537b5Aa1fefC07C",
    multicallAddress: "0xc4Fa95Fbf3A54d4A6870986B210096A92C751962",
    lockAddress: "0x0cb512b47b5B01687e01b5155C208e66768C823e",
    routername: "ETCswapV2",
    feeReceiver: "0xED011D569F3C07A7cd75020e35f7772A4B0392d7",
    dividendTracker: "0x1230992f9D209e271d6B3367677F5c177ADa1584",
  },
};
