import metcCoin from "../images/coins/mordor.png";
import etcCoin from "../images/coins/etc-black.png";

export const supportNetwork = {

  /*61: {
    name: "Ethereum Cassic",
    chainId: 61,
    rpc: "https://etc.rivet.link",
    image: etcCoin,
    symbol: "ETC",
  },*/

  63: {
    name: "Mordor Testnet",
    chainId: 63,
    rpc: "https://rpc.mordor.etccooperative.org",
    image: metcCoin,
    symbol: "METC",
  },

  default: {
    name: "Mordor Testnet",
    chainId: 63,
    rpc: "https://rpc.mordor.etccooperative.org",
    image: metcCoin,
    symbol: "METC",
  },
};

export const networkLists = [
  { code: 0, chainId: 61, label: 'Ethereum Classic', image: etcCoin },
  { code: 0, chainId: 63, label: 'Mordor Testnet', image: metcCoin },
]


export const RPC_URLS = {
  61: "https://etc.rivet.link",
  63: "https://rpc.mordor.etccooperative.org",
};
