export const currencies = {
  61: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "ETC" },
  ],
  63: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "METC" },
  ],
  default: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "METC" },
  ],
};
